<template>
	<div class="orderList">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">操作日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">商品名称:</div>
					<div class="item-input">
						<el-input v-model="searchForm.key" placeholder="请输入商品名称关键字" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择状态">
							<el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="l-item">
					<el-button type="warning"
						@click.stop="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
				<el-button type="warning" @click.stop="billingDialog = true, billingForm = {}">开单</el-button>
			</div>
			<el-table :data="tableData" style="width: 100%" border :header-cell-style="{
				background: '#f5f7fa',
				fontWeight: 'bold',
				color: '#303133'
			}" v-loading="loading">
				<el-table-column label="转换单号" prop="ordernumber" align="center"></el-table-column>
				<el-table-column label="操作日期" prop="date" align="center"></el-table-column>
				<el-table-column label="仓库" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.warehouse_info.name }} /
							{{ scope.row.warehouse_info.phone }}</span>
					</template>
				</el-table-column>
				<el-table-column label="原商品信息" align="center">

					<el-table-column label="主图" align="center">
						<template slot-scope="scope">
							<el-image style="width: 50px; height: 50px" :src="scope.row.from_goods_pic"
								:preview-src-list="[scope.row.from_goods_pic]">
							</el-image>
						</template>
					</el-table-column>
					<el-table-column label="名称" prop="from_goods_name" align="center"></el-table-column>
					<el-table-column label="卖点" prop="from_goods_intro" align="center"></el-table-column>
					<el-table-column label="自定义属性" prop="from_goods_attr" align="center"></el-table-column>
					<el-table-column label="数量(减少)" prop="from_goods_count" align="center"></el-table-column>

				</el-table-column>
				<el-table-column label="新商品信息" align="center">

					<el-table-column label="主图" align="center">
						<template slot-scope="scope">
							<el-image style="width: 50px; height: 50px" :src="scope.row.to_goods_pic"
								:preview-src-list="[scope.row.to_goods_pic]">
							</el-image>
						</template>
					</el-table-column>
					<el-table-column label="名称" prop="to_goods_name" align="center"></el-table-column>
					<el-table-column label="卖点" prop="to_goods_intro" align="center"></el-table-column>
					<el-table-column label="自定义属性" prop="to_goods_attr" align="center"></el-table-column>
					<el-table-column label="数量(增加)" prop="to_goods_count" align="center"></el-table-column>

				</el-table-column>
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0">未处理</span>
						<span v-if="scope.row.status == 1">已确认</span>
						<span v-if="scope.row.status == 9">已作废</span>
					</template>
				</el-table-column>

				<el-table-column label="备注" prop="remark" align="center"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="300">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click.stop="showDialog(1, scope.row)"
							v-if="scope.row.status == 0">确认</el-button>
						<el-button type="danger" size="mini" @click.stop="showDialog(9, scope.row)"
							v-if="scope.row.status == 0">作废</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="page" @size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" :current-page="page" :page-sizes="pageSizes"
				layout="total, sizes, prev, pager, next, jumper" :page-size="pageNum" :total="total">
			</el-pagination>
		</div>
		<el-dialog title="确认" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="form" :model="dialogForm" label-width="100px">
				<el-form-item label="修改库存：">
					<el-radio-group v-model="dialogForm.is_stock">
						<el-radio :label="0">不修改</el-radio>
						<el-radio :label="1">修改</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="operationFn()">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 开单 -->
		<el-dialog title="开单" :visible.sync="billingDialog" :close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="form" :model="billingForm" label-width="140px">
				<el-form-item label="原商品名称" required>
					<el-select v-model="billingForm.from_goods_id" filterable remote reserve-keyword
						placeholder="请选择原商品名称" :remote-method="searchGoods" @visible-change="goodsList = []"
						style="width: 100%;">
						<el-option v-for="item in goodsList" :key="item.id" :label="item.goods_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="新商品名称" required>
					<el-select v-model="billingForm.to_goods_id" filterable remote reserve-keyword
						placeholder="请选择新商品名称" :remote-method="searchGoods" @visible-change="goodsList = []"
						style="width: 100%;">
						<el-option v-for="item in goodsList" :key="item.id" :label="item.goods_name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="原商品数量(减少)" required>
					<el-input v-model="billingForm.from_goods_count" placeholder="请输入原商品数量"></el-input>
				</el-form-item>
				<el-form-item label="新商品数量(增加)" required>
					<el-input v-model="billingForm.to_goods_count" placeholder="请输入新商品数量"></el-input>
				</el-form-item>
				<el-form-item label="仓库" required>
					<el-select v-model="billingForm.warehouse_id" filterable placeholder="请选择仓库" style="width: 100%;">
						<el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="经办人" required>
					<el-input v-model="billingForm.handler" placeholder="请输入经办人"></el-input>
				</el-form-item>
				<el-form-item label="操作日期" required>
					<el-date-picker v-model="billingForm.date" type="date" placeholder="选择操作日期"
						value-format="yyyy-MM-dd" style="width: 100%;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="billingForm.remark" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="billingDialog = false">取 消</el-button>
				<el-button type="warning" @click.stop="billingConfirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				item: {},
				dialogForm: {
					is_stock: 0
				},
				// 状态
				statusOptions: [{
						id: 0,
						name: '未处理'
					},
					{
						id: 1,
						name: '已确认'
					},
					{
						id: 9,
						name: '已作废'
					}
				],
				dialogVisible: false,
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				pageSizes: [10, 50, 200, 1000],
				// 开单
				billingDialog: false,
				billingForm: {
					from_goods_id: '',
					to_goods_id: '',
				},
				goodsList: [], //商品列表
				warehouseList: [], //仓库

			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentDateFirst(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()
			// 仓库
			this.getWarehouse()
			// 修改库存
			if (window.localStorage.getItem('is_stock')) {
				this.dialogForm.is_stock = Number(window.localStorage.getItem('is_stock'))
			}
			console.log(this.dialogForm)
		},
		methods: {
			selectable(row, index) {
				if (row.status == 0) {
					return true;
				} else {
					return false;
				}
			},
			getList() {
				this.$http.post(`/erp/v1/goods/translate/lst`, {
					page: this.page,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 获取当前年月第一天
			getCurrentDateFirst() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');

				return `${year}-${month}-01`;
			},
			showDialog(type, item) {
				if (type == 1) {
					this.item = item
					this.dialogVisible = true
				} else {
					this.$confirm(`确定要作废吗?`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$http.post("/erp/v1/goods/translate/cancel", {
							id: item.id
						}).then((res) => {
							const {
								code,
								data,
								msg
							} = res.data
							if (code == 200) {
								this.getList()
								this.$message.success(msg);
								this.dialogVisible = false
							} else {
								this.$message.error(msg);
							}
						});
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				}
			},
			operationFn() {
				window.localStorage.setItem('is_stock', this.dialogForm.is_stock)
				this.$http.post("/erp/v1/goods/translate/confirm", {
					id: this.item.id,
					is_stock: this.dialogForm.is_stock
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.dialogVisible = false
						this.getList()
						this.$message.success(msg);
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 仓库
			getWarehouse() {
				this.$http.post(`erp/v1/warehouse/lst`, {
					pageNum: 100
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.warehouseList = data.rows
						if (this.warehouseList) {
							this.billingForm.warehouse_id = this.warehouseList[0].id
						}
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 搜索商品
			searchGoods(e) {
				this.$http.post(`erp/v1/goods/lst`, {
					key: e
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.goodsLoading = false
					if (code == 200) {
						this.goodsList = data.rows
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 开单确定
			billingConfirm() {
				if (!this.billingForm.from_goods_id) {
					this.$message.error('请选择原商品');
					return
				}
				if (!this.billingForm.to_goods_id) {
					this.$message.error('请选择新商品');
					return
				}
				if (this.billingForm.from_goods_id == this.billingForm.to_goods_id) {
					this.$message.error('转换商品不能是同一个商品');
					return
				}
				if (!this.billingForm.from_goods_count) {
					this.$message.error('请输入原商品数量');
					return
				}
				if (!this.billingForm.to_goods_count) {
					this.$message.error('请输入新商品数量');
					return
				}
				if (!this.billingForm.warehouse_id) {
					this.$message.error('请选择仓库');
					return
				}
				if (!this.billingForm.handler) {
					this.$message.error('请输入经办人');
					return
				}
				if (!this.billingForm.date) {
					this.$message.error('请选择操作日期');
					return
				}
				this.$http.post(`/erp/v1/goods/translate/add`, {
					...this.billingForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.billingDialog = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.export-template {
		#template-title {
			.title-t {
				text-align: center;
				margin-bottom: 20px;
				font-weight: bold;
				font-size: 22px;

			}

			.title-box {
				display: flex;
				flex-wrap: wrap;

				.box-item {
					width: 50%;
					display: flex;
					justify-content: center;
					margin-bottom: 20px;

					.item-i {
						width: 60%;

						span {
							padding-right: 20px;
						}
					}
				}
			}
		}
	}

	.page {
		margin-top: 20px;
		text-align: right;
	}
</style>